import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="range-slider"
export default class extends Controller {
  static targets = [ "input", "output" ]

  connect() {
    console.log("range_slider connect")
  }

  changed() {
    console.log(this.inputTarget.value);
    if (this.outputTarget) {
      this.outputTarget.textContent = this.inputTarget.value;
    }
  }

  finished() {
    console.log("this is where we would submit from")
    // this.inputTarget.form.submit();
  }
}
