import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "navbar" ]

  scroll(val) {
    // based on window scrollY, fade background opacity
    // use data-text-color-override for unscrolled text color if present so we can
    // handle background images
    var color_override = this.element.getAttribute("data-text-color-override").split(" ");

    if (window.scrollY < 32) {
      this.element.classList.remove("text-gray-900", "dark:text-white", "bg-white/95", "dark:bg-gray-900/95", "shadow-lg", "md:shadow-xl");
      this.element.classList.add(...color_override);
    } else {
      this.element.classList.remove(...color_override);
      this.element.classList.add("text-gray-900", "dark:text-white", "bg-white/95", "dark:bg-gray-900/95", "shadow-lg", "md:shadow-xl");
    }
  }
}